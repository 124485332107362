@tailwind base;
@tailwind components;
@tailwind utilities;

.or-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.or-text {
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 34px;
  height: 24px;
  background: white;
  display: flex;
  align-tracks: center;
  justify-content: center;
  top: 2px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pink-circle {
  background-color: rgba(246, 69, 154, 0.12);
}
.purple-circle {
  background-color: rgba(83, 69, 246, 0.12);
}
.blue-circle {
  background-color: rgba(77, 207, 236, 0.16);
}

.social-auth {
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.social-auth:hover {
  background-color: #f7f7f7;
}

.org-list {
  flex: 1 1;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.org-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.org-list .org-item:last-of-type {
  border-bottom: none;
}

.setup-pages-header {
  background: #ffffff;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
  height: 64px;
}

:root {
  --primary: #6646e5;
  --primary-rgb: 102, 70, 229;
}

.product-icon svg {
  height: 12px;
}

.react-tel-input .form-control {
  height: 40px !important;
  width: 100% !important;
}

input.form-control:focus {
  border: 1px solid var(--primary);
}

.react-autosuggest__suggestions-container--open {
  position: absolute;
  background: white;
  z-index: 10;
  width: 100%;
  left: 0;
  box-shadow: 0 2px 4px rgba(4, 7, 28, 0.08);
  top: 44px;
  max-height: 150px;
  overflow: auto;
}

.react-autosuggest__container {
  width: 100%;
}

.submit-btn {
  background-color: rgb(var(--color-primary));
}

.error-listing-tooltip-wrapper {
  text-align: left;
  padding: 10px 4px 10px 4px;
  z-index: 1000000;
}

.error-listing-tooltip-wrapper .title-wrapper {
  display: flex;
  color: black;
  font-weight: bold;
  margin-bottom: 4px;
  align-items: center;
}

.error-listing-tooltip-wrapper .icon-warning--red {
  color: #f63c5d;
}

.error-listing-tooltip-wrapper .error-list-items {
  color: black;
  font-weight: 500;
  line-height: 24px;
}

.profile-cover {
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.12));
}

.phone-number-display .form-control {
  width: fit-content !important;
  border: none !important;
  pointer-events: none;
  padding-left: 24px;
  height: unset !important;
}
.phone-number-display.react-tel-input {
  width: fit-content !important;
}

.phone-number-display .flag-dropdown {
  border: none !important;
  background: transparent !important;
  pointer-events: none !important;
}
.phone-number-display .selected-flag {
  padding: 0 !important;
}
.phone-number-display .arrow {
  display: none !important;
}

.multicolor-input {
  background: conic-gradient(
    from 180deg at 50% 50%,
    #fc4a44 0deg,
    #ffc81d 86.25deg,
    #40ff87 181.87deg,
    #1c82ff 277.5deg,
    #ff1836 360deg
  );
}

.SwitchPopover {
  z-index: 100004 !important;
}

.profile-editor-dob input[type="date"]::-webkit-inner-spin-button,
.profile-editor-dob input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.SwitchPopover {
  z-index: 12500;
}
.SwitchPopover .Popover-body {
  background: white;
  border: 1px solid #e6e6eb;
  border-radius: 0.5rem;
  border-top: 0px;
  box-shadow: 0 10px 20px 0 rgba(4, 7, 28, 0.08),
    10px 0px 40px rgba(0, 0, 0, 0.1);
}
.SwitchPopover .Popover-tip {
  z-index: 12501;
  fill: white;
}
.SwitchPopover .Popover-left .Popover-tip {
  margin-left: -1px;
}
.SwitchPopover .Popover-right .Popover-tip {
  margin-right: -1px;
}
.SwitchPopover .Popover-above .Popover-tip {
  margin-top: -1px;
}
.SwitchPopover .Popover-below .Popover-tip {
  margin-bottom: -1px;
}

.onboarding-container {
  height: calc(100vh - 64px);
}

.setup-pages-header {
  background: #ffffff;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
  height: 64px;
}

.navbar-chips {
  width: 200px;
  border-bottom: 1px solid transparent;
  font-weight: 400;
  font-size: 14px;
  margin-right: 8px;
  height: 43px;

  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-chips-active {
  color: black;
  border-color: black;
}

.navbar-chips .tick-icon-navbar {
  display: none;
}

.navbar-chips-active .tick-icon-navbar {
  display: unset;
}

.user-select-cards {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 36px rgba(0, 0, 0, 0.04);
  height: 264px;
  width: 420px;
}

.dots-background {
  background-color: rgba(0, 0, 0, 0.1);
}

.dots-background-active {
  background-color: #5345f6;
}
